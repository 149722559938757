import {
    BsFillHouseDoorFill,
    BsChatDotsFill,
    BsFillCalendarWeekFill,
    BsListUl,
    BsImages,
    BsEgg,
    BsGrid3X2Gap,
    BsCardHeading,
    BsPencilSquare,
    BsCalendar2CheckFill,
    BsPercent,
    BsSendPlusFill,
    BsBarChartLineFill,
} from "react-icons/bs"
import { BiTransfer } from "react-icons/bi"
import type { IconType } from "react-icons"

import type { OutfitterFieldsFragment } from "~graphql/generated/graphql"
import { PAGES, ROUTES } from "~config/constants"

export interface IMenuItem {
    id?: string
    name: string
    label: string
    link: string
    image?: string
    icon: IconType
    requiresActionPermission?: boolean
    children?: IMenuItem[]
}

export const items = {
    main: [
        {
            name: PAGES.HOME.name,
            label: PAGES.HOME.label,
            link: ROUTES.HOME,
            icon: BsFillHouseDoorFill,
        },
        {
            name: PAGES.INBOX.name,
            label: PAGES.INBOX.label,
            link: ROUTES.INBOX,
            icon: BsChatDotsFill,
            requiresActionPermission: true,
        },
        {
            name: PAGES.CALENDAR.name,
            label: PAGES.CALENDAR.label,
            link: ROUTES.CALENDAR,
            icon: BsFillCalendarWeekFill,
        },
        {
            name: PAGES.OUTFITTERS.name,
            label: PAGES.OUTFITTERS.label,
            link: ROUTES.OUTFITTERS,
            icon: BsImages,
        },
        {
            name: PAGES.BOOKINGS.name,
            label: PAGES.BOOKINGS.label,
            link: ROUTES.BOOKINGS,
            icon: BsCalendar2CheckFill,
        },
        {
            name: PAGES.QUOTES.name,
            label: PAGES.QUOTES.label,
            link: ROUTES.QUOTES,
            icon: BsSendPlusFill,
        },
        {
            name: PAGES.REFERRALS.name,
            label: PAGES.REFERRALS.label,
            link: ROUTES.REFERRALS,
            icon: BiTransfer,
        },
        {
            name: PAGES.COUPONS.name,
            label: PAGES.COUPONS.label,
            link: ROUTES.COUPONS,
            icon: BsPercent,
        },
        {
            name: PAGES.REPORTS.name,
            label: PAGES.REPORTS.label,
            link: ROUTES.REPORTING,
            icon: BsBarChartLineFill,
            requiresActionPermission: true,
        },
    ],
    assets: [
        {
            name: "admin-amenities",
            label: "Amenities",
            link: "/admin/assets/amenities",
            icon: BsGrid3X2Gap,
        },
        {
            name: "admin-species",
            label: "Species",
            link: "/admin/assets/species",
            icon: BsEgg,
        },
        {
            name: "admin-license",
            label: "Licenses",
            link: "/admin/assets/license-infos",
            icon: BsCardHeading,
        },
        {
            name: "admin-seo-pages",
            label: "SEO Pages",
            link: "/admin/assets/seo-pages",
            icon: BsPencilSquare,
        },
    ],
}

export function addOutfittersToItems({
    outfittersByOwner,
    shouldShowActions,
}: {
    outfittersByOwner?: OutfitterFieldsFragment[]
    shouldShowActions: boolean
}): IMenuItem[] {
    const outfitterList: IMenuItem[] =
        outfittersByOwner?.map((element, index) => {
            return {
                id: element.id,
                name: `outfitter${index + 1}`,
                label: element.name,
                link: `/outfitter/${element.id}`,
                image: element.avatar?.url,
                icon: BsImages,
                children: element.listings.map((listing, idx) => {
                    return {
                        id: listing.id,
                        name: `listing${idx + 1}`,
                        label: `${listing.title}`,
                        link: `/manage-listings/${listing.id}`,
                        icon: BsListUl,
                    }
                }),
            }
        }) ?? []
    const filteredMainItems = items.main.filter(
        (item) => !item.requiresActionPermission || shouldShowActions
    )

    const mainList: IMenuItem[] = [...filteredMainItems, ...outfitterList]

    return mainList
}
