import type { Theme, ThemeConfig } from "@chakra-ui/react"
import { defineStyle, extendTheme } from "@chakra-ui/react"
import { mode } from "@chakra-ui/theme-tools"

interface GlobalProps {
    colorMode: string
    theme: Record<string, unknown>
}

const config: ThemeConfig = {
    useSystemColorMode: true,
}

const CUSTOM_COLORS = {
    gray: "#727272",
    lightGray: "#878787",
    darkPurple: "#271E4A",
    offWhite: "#F5F8FD",
    burntOrange: "#DC340A",
    silver: "#7B7B7B",
    brightOrange: "#F76539",
    salmon: "#FF706E",
    aqua: "#3F779B",
    mustard: "#FFBE40",
    shareBackground: "#4A8AEE",
    shareBackgroundHover: "#3672D1",
    highlight: "#F76539",
    hubspotOrange: "#FB6E10",
    focusBorderColor: "#F54D23",
} as const

const ENTITY_COLORS = {
    dark: "black",
    light: "white",
} as const

const colors = {
    brand: {
        50: "#FFE8E1",
        100: "#FFC2B3",
        200: "#FB9B83",
        300: "#F87453",
        400: "#F55E38",
        500: "#F54D23",
        600: "#AC2707",
        700: "#7B1B04",
        800: "#4C0E00",
        900: "#1F0200",
    },
    custom: CUSTOM_COLORS,
    entityColors: ENTITY_COLORS,
} as const

const shadows = {
    blue: {
        xs: "0 0 0 1px rgba(49, 130, 206, 0.05)",
        sm: "0 1px 2px 0 rgba(49, 130, 206, 0.05)",
        base: "0 1px 3px 0 rgba(49, 130, 206, 0.1), 0 1px 2px 0 rgba(49, 130, 206, 0.06)",
        md: "0 4px 6px -1px rgba(49, 130, 206, 0.1), 0 2px 4px -1px rgba(49, 130, 206, 0.06)",
        lg: "0 10px 15px -3px rgba(49, 130, 206, 0.1), 0 4px 6px -2px rgba(49, 130, 206, 0.05)",
        xl: "0 20px 25px -5px rgba(49, 130, 206, 0.1), 0 10px 10px -5px rgba(49, 130, 206, 0.04)",
        "2xl": "0 25px 50px -12px rgba(49, 130, 206, 0.25)",
    },
}

const fonts = {
    body: `"Nunito Sans", sans-serif`,
    heading: `"Nunito Sans", sans-serif`,
    mono: `"Nunito Sans", sans-serif`,
}

const styles = {
    global: ({ colorMode }: GlobalProps) => ({
        body: {
            backgroundColor: colorMode === "dark" ? "gray.900" : "white",
            overscrollBehavior: "none",
            scrollBehavior: "smooth",
        },
    }),
}

const primaryButtonVariant = defineStyle((props) => ({
    background: "custom.brightOrange",
    color: mode("white", "black")(props),
    _hover: {
        background: mode("orange.600", "orange.500")(props),
    },
}))

const components = {
    Button: {
        variants: {
            primary: primaryButtonVariant,
        },
    },
} as const

const customTheme = { config, colors, fonts, styles, shadows, components }

export type CustomTheme = typeof customTheme & Theme

export default extendTheme(customTheme)
