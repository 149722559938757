import { useToast, type UseToastOptions } from "@chakra-ui/react"

import COPY from "~config/copy-constants"

const COMMON_TOAST_OPTIONS: UseToastOptions = {
    position: "top",
    duration: 5000,
    isClosable: true,
}

export function useToastFeedback(
    successMessage = "",
    errorMessage = ""
): {
    onCompleted: () => void
    onError: (error?: Error) => void
} {
    const { showSuccessToast, showErrorToast } = useSuccessAndErrorToast()

    return {
        onCompleted: () => showSuccessToast(successMessage),
        onError: (error?: Error) =>
            showErrorToast(errorMessage ? new Error(errorMessage) : error),
    }
}

export function useSuccessAndErrorToast() {
    const showSuccessToast = useSuccessToast()
    const showErrorToast = useErrorToast()

    return { showSuccessToast, showErrorToast }
}

function useSuccessToast() {
    const toast = useToast()

    return (description: string) =>
        toast({
            ...COMMON_TOAST_OPTIONS,
            status: "success",
            title: COPY.SUCCESS,
            description,
        })
}

export function useErrorToast() {
    const toast = useToast()

    return (error?: Error) =>
        toast({
            ...COMMON_TOAST_OPTIONS,
            status: "error",
            title: COPY.ERROR,
            description: error?.message,
        })
}
