import React from "react"
import { Fade, Flex } from "@chakra-ui/react"

import Spinner from "~components/shared/spinner"
import TextLogo from "~components/shared/header/logo"

export default function AuthenticatingLoader() {
    return (
        <Fade in>
            <Flex
                width="100vw"
                height="100vh"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
            >
                <Spinner size="md" thickness="2px" />
                <TextLogo mt={6} w="220px" />
            </Flex>
        </Fade>
    )
}
