import React, { useContext } from "react"
import {
    Avatar,
    IconButton,
    Menu,
    MenuButton,
    useColorModeValue,
} from "@chakra-ui/react"

import { UserContext } from "~config/context-providers/user-provider"
import ProfileMenu from "~components/shared/profile-menu/profile-menu"

export default function ProfileButton() {
    const { user, isLoading } = useContext(UserContext)

    const avatarBorderColor = useColorModeValue("brand.100", "brand.800")

    return (
        <Menu placement="bottom-end" preventOverflow>
            <MenuButton
                as={IconButton}
                rounded="full"
                variant="unstyled"
                isLoading={isLoading}
                icon={
                    <Avatar
                        size="sm"
                        boxSize="40px"
                        name={`${user?.first_name} ${user?.last_name}`}
                        src={user?.avatar_url || ""}
                        borderWidth="1.5px"
                        borderColor={avatarBorderColor}
                        transition="all 0.2s ease-in-out"
                        _hover={{ borderColor: "brand.300" }}
                    />
                }
            />
            <ProfileMenu showAccountSettings />
        </Menu>
    )
}
