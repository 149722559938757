const COPY_CONSTANTS = {
    EDIT: "Edit",
    ADD: "Add",
    REMOVE: "Remove",
    CREATE: "Create",
    UPDATE: "Update",
    CHANGE: "Change",
    MANAGE: "Manage",
    DATES: "Dates",
    SELECT: "Select",
    MENU: "Menu",
    UPDATED_BOOKING_SUCCESS: "Successfully updated booking!",
    INVALID_SLOTS: "Invalid slots",
    GROUP_PAY_MEMBER_SLOTS: "Group pay member slots",
    SLOTS: "Slots",
    TOTAL_SLOTS: "Total Slots",
    BOOKED_SLOTS: "Booked Slots",
    AVAILABLE_SLOTS: "Available Slots",
    TOTAL_SLOTS_EXPLANATION: 'Calculated by "Total slots" - "Booked slots"',
    BOOKING: "Booking",
    BOOKING_BY: "Booking by",
    GUEST: "Guest",
    CHANGE_BOOKING: "Change booking",
    NUMBER_OF_GUESTS: "Number of guests",
    NUMBER_OF_DAYS: "Number of days",
    CLOSE: "Close",
    CANCEL: "Cancel",
    NAME: "Name",
    SAVE: "Save",
    CONFIRM: "Confirm",
    CHANGES_PREVIEW: "Changes preview",
    NO_CHANGES: "No changes",
    CURRENT: "Current",
    NEW: "New",
    REFUNDED: "Refunded",
    REFUNDS: "Refunds",
    CANCEL_REFUND_IN_STRIPE: "Cancel",
    CREATE_REFUND: "Create Refund",
    AMOUNT: "Amount",
    REASON: "Detailed reason",
    BY: "By",
    ON: "On",
    STATUS: "Status",
    APPROVED_BY: "Approved by",
    CANCELLED_BY: "Cancelled by",
    AT: "At",
    OVERVIEW: "Overview",
    STRIPE_REASON: "Stripe refund reason",
    RESPONSIBLE_PARTY: "Is outfitter responsible for refund?",
    MANUAL_REFUND_OWED: "Manual Refund Owed",
    MANUAL_REFUND_COMPLETED: "Manual Refund Completed",
    CHANGE_BOOKING_WARNING:
        "This is the old way of updating a booking. This is only being used for older bookings that lack the necessary info for the new method. All new bookings will use the new version.",
    LOADING: "Loading...",
    SELECT_OUTFITTER: "Select outfitter",
    WELCOME: "Welcome",
    INVITED_TO_JOIN: "You have been invited to join",
    ACCEPT_INVITATION: "Accept Invitation",
    INVITATION_ACCEPTED: "Invitation successfully accepted!",
    DOWNLOAD_APP_TO_GET_STARTED: "Download the mobile app to get started.",
    DOWNLOAD_MOBILE_APP: "Download Mobile App",
    PRICING_PACKAGE_HAS_LODGING_WARNING:
        "The name of this package indicates that it includes lodging accommodation. Please make sure that lodging is included in the price & marked as included in the package.",
    ONBOARD_CONTINUE_BUTTON_LABEL: "Continue",
    FILE_EXCEEDS_LIMIT: "The file you selected exceeds the limit of",
    ADD_IMAGE: "Add image",
    ADD_IMAGES: "Add images",
    CONFIRM_YOUR_INFO: "Confirm your info before continuing",
    FIRST_NAME: "First name",
    LAST_NAME: "Last name",
    PHONE_NUMBER: "Phone number",
    USER_UPDATE_SUCCESS: "👍🏼 User info successfully updated",
    ASSIGN_OUTFITTER_TO_USER_SUCCESS: "🎉 You've signed up as an outfitter",
    BOOKING_CREATE_SUCCESS: "Successfully created booking",
    OFFSITE_BOOKING_UPDATE_SUCCESS: "Succesfully updated off-site booking",
    ENTER_ALL_CORRECT_FIELDS: "Enter all correct fields",
    SHARE: {
        TEXT: "Text",
        EMAIL: "Email",
        SHARE: "Share",
        ERROR: "Failed to share",
        NATIVE_SHARE_ERROR: "Native share not supported",
        NATIVE_SHARE_BUTTON: "Text and more...",
    },
    COPY: {
        COPY: "Copy",
        COPIED: "Copied!",
        COPIED_TO_CLIPBOARD: "Copied to clipboard",
        COPY_BUTTON_ARIA_LABEL: "Copy to clipboard",
        CLIPBOARD_NOT_SUPPORTED: "Clipboard not supported",
    },
    CREATE_LISTING_FORM_LABELS: {
        TITLE: "Listing name",
        LOCATION: "Address",
        CHECK_IN_MINUTES: "Check-in time",
        CHECK_OUT_MINUTES: "Check-out time",
        TYPE: "Listing type",
        LISTING_LICENSE: "Listing license",
        MIN_GUEST: "Minimum guests",
        MAX_GUEST: "Maximum guests",
        LICENSE_REQUIRED: "Listing license",
    },
    CREATE_LISTING_FORM_SUB_LABELS: {
        TYPE: "Please select which type of listing you will provide",
        LICENSE_REQUIRED: "Does this listing require a license from sportsmen?",
        MIN_GUEST: "The minimum amount of guests required to book.",
        MAX_GUEST: "The maximum amount of guests allowed to book.",
    },
    GROUP_PAY: {
        LABEL: "Group pay",
        ORGANIZER: "Organizer",
        JOINED: "Joined",
        INVITED: "Invited",
        SLOTS_CLAIMED: "slots claimed",
        MEMBER_UPDATE_BUTTON: "Update member info",
        MEMBER_UPDATE_SUCCESS_MESSAGE: "Group member info updated successfully",
        MEMBER_UPDATE_MODAL_TITLE: "Update group member info",
        MEMBER_UPDATE_FORM_LABELS: {
            NAME: "Name",
            EMAIL: "Email",
            PHONE: "Phone number",
        },
        GROUP_PROGRESS_COMPLETE: "group complete",
        GROUP_PROGRESS_INCOMPLETE: "group incomplete",
        INVITE_MEMBER_LINK_LABEL: "Invite new members via the following link",
        INVITE_MEMBER_LINK_VALID_UNTIL: "Valid until",
    },
    SEO_PAGES: {
        DELETE_DIALOG_TITLE: "Delete SEO Page",
        DELETE_DIALOG_BODY:
            "Are you sure to delete this page? You can't undo this action afterwards.",
        DELETE_DIALOG_SUCCESS: "Successfully deleted SEO page",
    },
    PAYMENTS: "Payments",
    ADDTIONAL_PAYMENT: "Additional payment",
    GROUP_PAYMENTS: "Group payments",
    AWAITING_PAYMENTS: "Awaiting payments",
    ALL_PAYMENTS_RECEIVED: "All payments received",
    REQUEST_PAYMENT: "Request payment",
    RECORD_CASH_CHECK: "Record cash/check",
    REQUEST_ADDITIONAL_PAYMENT: "Request additional payment",
    RECORD_PAYMENT: "Record Payment",
    APPROVE_BOOKING: "Approve booking",
    DENY_BOOKING: "Deny booking",
    ADDITIONAL_PAYMENT_ACTION_SELECTOR_LABEL:
        "Are you requesting a new payment or recording a cash/check payment?",
    HOW_WILL_PAYMENT_BE_COLLECTED: "How will this payment be collected?",
    HOW_WAS_PAYMENT_RECEIVED: "How was this payment received?",
    WOULD_YOU_LIKE_TO_COLLECT_TAX_ON_THIS_PAYMENT_REQUEST:
        "Would you like to collect sales tax on this payment request?",
    DO_NOT_COLLECT_TAX_PAYMENT_REQUEST:
        "Do not collect sales tax on this payment request",
    COLLECT_TAX_ON_SUBTOTAL_PAYMENT_REQUEST:
        "Collect sales tax on the subtototal of this payment request",
    PAID_OFFLINE: "Paid offline",
    SENDS_INVOICE_TO_SPORTSMAN: "Sends invoice to sportsman to pay online",
    PROVIDES_QR_CODE_TO_SPORTSMAN:
        "Provides a QR code to sportsman to scan and pay in person",
    SHOW_QR_CODE_TO_SPORTSMAN: "Show this QR code to sportsman",
    SHARE_PAYMENT_REQUEST_LINK: "Share the payment request link with sportsman",
    TOTAL: "Total",
    ADD_ANOTHER_PAYMENT: "Add another payment",
    PAYMENT_REQUESTS: "Payment requests",
    REQUESTED_PAYMENT_SUCCESS: "Successfully requested payment!",
    REQUESTED_PAYMENT_FAILED: "Error requesting payment.",
    RESOLVED_PAYMENT_REQUEST_SUCCESS: "Successfully resolved payment request!",
    RESOLVED_PAYMENT_REQUEST_FAILED: "Error resolving payment request.",
    GAS: "Gas",
    RECEIVED_PAYMENT_IN_PERSON: "Received this payment in person?",
    MARK_PAYMENT_REQUEST_RECEIVED: "Mark this payment request as received?",
    PAYMENT_REQUEST_RECEIVED_NOTE:
        "This will resolve the payment request and no further action will be required by the sportsman.",
    ENTER_AMOUNT_TO_REQUEST: "Enter amount to be requested",
    EVENTS_UPDATED: "Events updated",
    NO_EVENTS_IN_RANGE: "There are no bookings in this range.",
    SHOW_OFF_PLATFORM_BOOKINGS: "Show off-platform bookings",
    SHOW_MALLARD_BAY_BOOKINGS: "Show Mallard Bay bookings",
    TOTAL_COMPLETED_BOOKINGS: "Total completed bookings",
    CREATED_AT: "Created at",
    DETAILS: "Details",
    DESCRIPTION: "Description",
    VIEW_DETAILS: "View details",
    VIEW_BOOKING: "View booking",
    CHECK_IN: "Check-in",
    GUESTS: "Guests",
    ADD_ONS: "Add Ons",
    EMAIL: "Email",
    CALL: "Call",
    VIEW: "View",
    DELETE: "Delete",
    SUSPEND: "Suspend",
    EMAIL_OWNER: "Email owner",
    REASSIGN_OWNER: "Reassign owner",
    CALENDAR: "Calendar",
    UPDATE_AVAILABILITY: "Update availability",
    SUBSCRIBE: "Subscribe",
    SUBSCRIBE_TO_CALENDAR: "Subscribe to calendar",
    APPLE_CALENDAR: "Apple Calendar",
    GOOGLE_CALENDAR: "Google Calendar",
    OUTLOOK_CALENDAR: "Outlook Calendar",
    LISTINGS: "Listings",
    LISTING: "Listing",
    LODGE: "Lodge",
    OTHERS: "Others",
    OFF_PLATFORM: "Off-platform",
    HIDE: "Hide",
    SHOW: "Show",
    MORE: "more",
    CASH: "Cash",
    CHECK: "Check",
    OTHER: "Other",
    EMAIL_INVOICE: "Email Invoice",
    QR_CODE: "QR Code",
    QR_CODE_SHARE_DEFAULT_LABEL: "Show the QR code",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    MY_OUTFITTERS: "My outfitters",
    NEW_OUTFITTER: "New outfitter",
    NO_OUTFITTERS_MESSAGE: "You haven't onboarded any outfitters yet",
    WIDGETS: "Widgets",
    ADD_WIDGET: "Add Widget",
    NO_WIDGETS_YET: "No widgets yet",
    UPDATED: "Updated",
    GET_INSTRUCTIONS: "Get instructions",
    REVIEWS: "Reviews",
    OVERALL_GOOGLE_RATING: "Overall Google Reviews Rating",
    OVERALL_NATIVE_RATING: "Overall Native Reviews Rating",
    NA: "N/A",
    LODGING: "Lodging",
    ADD_LODGING: "Add Lodging",
    NO_LODGINGS_YET: "No lodgings yet",
    COUPONS: "Coupons",
    ADD_COUPON: "Add coupon",
    NO_COUPONS_LEFT: "No coupons left",
    DELETE_COUPON_SUCCESS: "Successfully deleted coupon",
    REORDER: "Reorder",
    ADD_A_LISTING: "Add a listing",
    NO_LISTINGS: "No Listings",
    EDIT_OUTFITTER: "Edit outfitter",
    ADD_BOOKING: "Add booking",
    FOR: "For",
    DUE: "Due",
    OR: "or",
    MANAGE_REFUNDS: "Manage refunds",
    MANAGE_CALENDAR: "Manage calendar",
    APPROVE: "Approve",
    DECLINE: "Decline",
    ACCEPT: "Accept",
    OPEN_CALENDAR: "Open calendar",
    GO_TO_CALENDAR: "Go to calendar",
    AVAILABILITY_SUGGESTION_NOTE:
        "It's essential to regularly update your availability. This enables sports enthusiasts to plan their trips efficiently and ensures that your services are consistently booked.",
    AVAILABILITY_BANNER_TITLE: "Please Add Your Availability",
    PER_PERSON: "per person",
    PER_DAY: "per day",
    EDIT_PRICING: "Edit pricing",
    NO_METRICS_AVAILABLE: "No metrics available",
    TOTAL_GROSS_BOOKINGS: "Total gross bookings",
    GROSS_REVENUE: "Gross Revenue",
    BOOKINGS_GROSS_REVENUE_BY_MONTH: "Bookings Gross Revenue By Month",
    BOOKINGS_COUNT_BY_MONTH: "Bookings Count By Month",
    BOOKINGS_GROSS_REVENUE_BY_SOURCE: "Bookings Gross Revenue By Source",
    BOOKINGS_COUNT_BY_SOURCE: "Bookings Count By Source",
    BOOKINGS_GROSS_REVENUE_BY_CUSTOMER: "Bookings Gross Revenue By Customer",
    BOOKINGS_COUNT_BY_CUSTOMER: "Bookings Count By Customer",
    BOOKINGS: "Bookings",
    REVENUE_FORECAST: "Revenue Forecast",
    PAYMENTS_NET_REVENUE_BY_TYPE: "Payments Net Revenue By Type",
    PAYMENTS_COUNT_BY_TYPE: "Payments Count By Type",
    FOR_SELECTED_DATES: "For selected dates",
    COMPLETED_BOOKINGS_OVER_TIME:
        "Completed bookings over time (Last 12 months)",
    ACH_NOTE: "ACH - Additional 1-2 days processing",
    STRIPE_INVOICE: "Stripe invoice",
    STRIPE_RECEIPT: "Stripe receipt",
    AUTHORIZE_NET_TRANSACTION: "Authorize.net transaction",
    ADD_TO_APPLE_CALENDAR: "Add to Apple Calendar",
    ADD_TO_GOOGLE_CALENDAR: "Add to Google Calendar",
    ADD_TO_YOUR_CALENDAR: "Add to your calendar",
    MALLARD_BAY_TRIP: "Mallard Bay Trip",
    SELECT_PRICING_PACKAGE: "Select a Pricing Package",
    GROUP_PACKAGES: "Group packages",
    PER_PERSON_PACKAGES: "Per person packages",
    UNTITLED: "Untitled",
    TYPE: "Type",
    TODAY: "Today",
    PREVIOUS: "Previous",
    NEXT: "Next",
    SELECTED_DATES: "SELECTED DATES",
    CHOOSE_ONE: "Choose One",
    NONE: "None",
    AVAILABLE: "Available",
    UNAVAILABLE: "Unavailable",
    AVAILABILITY: "Availability",
    AVAILABILITY_UPDATED: "Availability updated",
    REMAINING_SLOTS_OUT_OF: "remaining slots out of",
    NOTES: "Notes",
    NOTE: "Note",
    ADD_OFFLINE_BOOKING: "Add offline booking",
    DATE_NOTES: "Date notes",
    SELECT_RESOURCE: "Select resource",
    LAST_UPDATED: "Last updated",
    OPTIONS: "Options",
    OPTIONAL: "Optional",
    INCLUDED_LODGE: "Included lodge",
    LODGING_SELECTION_EXPLANATION:
        "Selecting a lodge will keep track of lodging availability for you when sportsmen book trips. It will also show the sportsmen details about their acomodations.",
    NO_RESOURCE_ID_AND_TYPE_ERROR:
        "Unable to update availability. Missing resource ID or type",
    NO_IS_AVAILABLE: "Unable to update availability. Missing isAvailable",
    NO_TOTAL_SLOTS:
        "Unable to update availability. Missing totalSlots for isAvailable = true",
    TRIP_PACKAGES: "Trip packages",
    NO_ADD_ONS_AVAILABLE: "There are no add-ons available for this listing",
    TOTAL_GUESTS: "Total Guests",
    GUESTS_PACKAGE_NOTE: "The selected pricing package is set to include",
    MAX_GUESTS_MESSAGE:
        "The max number of guests available for the selected dates is",
    MIN_GUESTS_MESSAGE: "The min number of guests for the selected package is",
    COUPON: "Coupon",
    NO_COUPONS_CREATED: "There are no coupons created for",
    FILTER_BY_LISTING: "Filter by listing",
    CREATE_QUOTE_SUCCESS: "Successfully created quote!",
    CREATE_QUOTE_ERROR: "Error creating quote.",
    UPDATE_QUOTE_SUCCESS: "Successfully updated quote!",
    UPDATE_QUOTE_ERROR: "Error updating quote.",
    EDIT_QUOTE: "Edit Quote",
    CREATE_QUOTE: "Create Quote",
    PRICING_PACKAGE: "Pricing Package",
    NO_PRICING_PACKAGES_PREFIX: "There are no pricing packages created for",
    NO_PRICING_PACKAGES_SUFFIX: "Please go to the listing page to create them.",
    BACK: "Back",
    GO_BACK: "Go back",
    CONTINUE: "Continue",
    NOTES_HELP_MESSAGE: "Add any internal notes you may need for this quote.",
    NOTES_PLACEHOLDER: "3 day with Johnny Appleseed and his 5 brothers...",
    NO_DATES_SELECTED: "No dates selected",
    PACKAGES: "Packages",
    NO_PACKAGES_MESSAGE: "No packages. Please add at least on package.",
    ADD_A_PACKAGE: "Add a Package",
    ADD_ANOTHER_PACKAGE: "Add Another Package",
    SEE_DETAILS: "See details",
    BACK_TO_PREVIOUS_STEP: "Back to previous step",
    SUBMIT: "Submit",
    GROUP_PACKAGE_VS_PAY_PER_PERSON: "Group package vs. pay per person?",
    PACKAGE_TYPE_HELPER_TEXT:
        "The package type changes how sportsmen will be charged.",
    GROUP_PACKAGE_HELPER_TEXT:
        "This type is for packages where there is a set number of sportsmen included, so you should set the price for the group as a whole.",
    PAY_PER_PERSON_HELPER_TEXT:
        "This type is for packages where sportsmen can select how many guests will be attending. The price should reflect how much it is for each guest.",
    THERE_ARE: "There are",
    LISTINGS_WITH_THIS_ADD_ON:
        "with pricing packages that include this add-on.",
    APPLIES_TO_ALL_LISTINGS: "Applies to all listings",
    THIS_ADD_ON_CANNOT_BE_DISABLED:
        "This add-on cannot be disabled because it is set to apply to all listings.",
    DISABLED_FOR_LISTING: "Disabled for listing",
    DISABLED: "Disabled",
    ENABLED: "Enabled",
    DELETE_ADD_ON: "Delete add-on",
    DELETE_ADD_ON_WARNING:
        "Are you sure you want to delete this add-on? This action cannot be undone.",
    APPLY_TO_ALL_LISTINGS: "Apply to all listings?",
    IS_HIDDEN_FROM_SPORTSMEN: "Hide this add-on from Sportsmen at checkout?",
    IS_HIDDEN_FROM_SPORTSMEN_TOOLTIP:
        "Turn this on if you want to make this add-on visible only to you and your staff when requesting payments, and not visible to sportsmen during checkout.",
    PRICING: {
        PER_DAY: "/ day",
        PER_GUEST: "/ guest",
        DEPOSIT: "Deposit",
        TOTAL: "Total",
        NET: "Net",
        PAID_BY: "Paid by",
        DUE_AFTER_TRIP: "Due after trip",
    },
    DEFAULT_IMAGE: "Default image",
    DEFAULT: "Default",
    CUSTOM_IMAGE: "Custom image",
    VS: "vs.",
    DEFAULT_IMAGE_HELPER_TEXT:
        "If you don't have a custom image, we'll use this one.",
    CUSTOM_IMAGE_HELPER_TEXT:
        "Upload a custom image for this add-on. This will replace the default image.",
    UPFRONT_PAYMENT_STRUCTURE: "Upfront payment structure",
    CUSTOMIZE_UPFRONT_PAYMENT_STRUCTURE_HELPER_TEXT:
        "Customize the upfront payment structure for this add-on. Select how much is due before the trip, if any.",
    CONFIRMATION_STEP_HELPER_TEXT:
        "Here's how your Add-on will look to guests at checkout",
    DECREASE: "Decrease",
    INCREASE: "Increase",
    YES: "Yes",
    NO: "No",
    TRUE: "True",
    FALSE: "False",
    PRICING_PACKAGE_CREATED_SUCCESSFULLY:
        "Pricing package created successfully",
    PRICING_PACKAGE_CREATION_FAILED: "Failed to create pricing package",
    STEP: "Step",
    NOT_INCLUDED: "Not included",
    GROUP_PACKAGE: "Group package",
    PAY_PER_PERSON: "Pay per person",
    SET_DAY: "Set # of days",
    PAY_PER_DAY: "Pay per day",
    PACKAGE_NAME: "Enter a name for this package",
    PACKAGE_TYPE: "What kind of package is this?",
    DATE_TYPE: "How should dates be selected?",
    PACKAGE_PRICE: "Enter the price for this package",
    GUESTS_INCLUDED: "How many guests are included in this package?",
    PER_GUEST_INPUT: "How many guests can be booked for this package?",
    MIN_GUESTS: "Min guests",
    MAX_GUESTS: "Max guests",
    DAYS_INCLUDED: "How many days are included in this package?",
    DOES_THIS_PACKAGE_INCLUDE: "Does this package include",
    LODGING_ACCOMMODATION: "lodging accommodation",
    TROPHY_FEES: "trophy fees",
    GEAR_RENTALS: "gear rentals",
    IN_THE_PRICE: "in the price?",
    SELECTED_TROPHY_FEES: "Which trophy fees are included in this package?",
    SELECTED_GEAR_RENTALS: "Which gear rentals are included in this package?",
    SELECTED_MEAL_UPGRADES: "Which meal upgrades are included in this package?",
    IS_OTHER_ADDONS_INCLUDED:
        "Are there any other add-ons included in the price for this package?",
    SELECTED_OTHER_ADDONS: "Which other add-ons are included in this package?",
    LODGINGS: {
        ADD_ROOM: "Add room",
        EDIT_ROOM: "Edit room",
        DELETE_ROOM: "Delete room",
        DELETE_ROOM_CONFIRMATION: "Are you sure to delete this room?",
        LODGING_CREATED_SUCCESS: "Lodging successfully created",
        LODGING_UPDATED_SUCCESS: "Lodging successfully updated",
        LODGING_DELETED_SUCCESS: "Lodging successfully deleted",
        ROOM_TYPE: "Room type",
        ROOM_NAME: "Room name",
        ROOM_DESCRIPTION: "Description",
        ROOM_NAME_PLACEHOLDER:
            "Ex: Living Room, Bedroom 1, or a unique name for the room",
        SLEEPING_ARRANGEMENTS: "Sleeping Arrangements",
        REMOVE_LODGING_IMAGE_ARIA_LABEL: "Remove lodging image",
        REMOVE_LODGING_WARNING_TITLE: "Affected pricing packages",
        REMOVE_LODGING_WARNING_DESCRIPTION:
            "Deleting this room will also remove it as the included lodging option from pricing packages. Bookings already made with these pricing packages will not be affected.",
    },
    IMAGES: "Images",
    BOOKING_FEE: "Booking Fee",
    CARD_PROCESSING_FEE: "Card Processing Fee",
    ACH_PROCESSING_FEE: "ACH Processing Fee",
    OUTFITTER_FEE: "Outfitter Fee",
    OUTFITTER_NET: "Outfitter Net",
    SALES_TAX: "Sales Tax",
    BOOKING_FEE_RATE: "Booking Fee Rate",
    BOOKING_FEE_FIXED: "Booking Fee Fixed",
    MAX_BOOKING_FEE: "Max Booking Fee",
    PROCESSING_FEE_RATE: "Processing Fee Rate (CC)",
    PROCESSING_FEE_FIXED: "Processing Fee Fixed (CC)",
    PROCESSING_FEE_RATE_ACH: "Processing Fee Rate (ACH)",
    PROCESSING_FEE_FIXED_ACH: "Processing Fee Fixed (ACH)",
    MAX_PROCESSING_FEE_ACH: "Max Processing Fee (ACH)",
    PROCESSING_FEE_PAYED_BY_SPORTSMAN: "Processing Fee Paid By Sportsman",
    PROCESSING_FEE_SETTINGS: "Processing Fee Settings",
    PAYMENT_PROCESSING_FEE: "Payment Processing Fee",
    MALLARD_BAY_FEE: "Mallard Bay Fee",
    PAID_BY_SPORTSMAN: "Paid By Sportsman",
    OUTFITTER_FEE_RATE: "Outfitter Fee Rate",
    OUTFITTER_FEE_FIXED: "Outfitter Fee Fixed",
    TAX_RATE: "Tax Rate",
    BOOKING_TAX_RATE: "Booking Tax Rate",
    RATES: "Rates",
    EDIT_RATES: "Edit Rates",
    GEAR_RENTAL: "Gear Rental",
    MEAL_UPGRADE: "Meal Upgrade",
    SAVING_ADD_ON: "Saving add-on...",
    SAVING_PRICING_PACKAGE: "Saving pricing package...",
    FULL_DEPOSIT: "Full Deposit",
    PARTIAL_DEPOSIT: "Partial Deposit",
    IMAGE_UPDATED: "Image Updated",
    REQUIRES_UPFRONT_PAYMENT: "Requires upfront payment",
    PERCENTAGE: "Percentage",
    FLAT_AMOUNT: "Flat Amount",
    ADD_ON_LABELS: {
        NAME: "Enter a name for your Add-on",
        DESCRIPTION: "Add a description for your Add-on",
        UNIT_PRICE: "Enter a price for your Add-on",
        DEPOSIT_AMOUNT: "How much does the guest need to pay upfront?",
        DEPOSIT_PERCENTAGE:
            "What percentage of the price does the guest need to pay upfront?",
        IMAGE: "Image",
        HAS_DEPOSIT: "Does your Add-on require payment before the trip?",
        DEPOSIT_TYPE:
            "Does the guest need to pay the full amount or a partial amount before the trip?",
        PARTIAL_DEPOSIT_TYPE:
            "Does the guest need to pay a flat amount or a percentage of the add on price?",
        SPECIES: "Select a Species",
        FULL_DEPOSIT: "Full Amount",
        PARTIAL_DEPOSIT: "Partial Amount",
        PERCENTAGE_DEPOSIT: "Percentage",
        AMOUNT_DEPOSIT: "Amount",
        GUESTS_TYPE: "Is this price for all guests or per guest?",
        DATE_TYPE: "Is this price for all days or per day?",
        GROUP_PACKAGE: "All Guests",
        PER_GUEST: "Per Guest",
        SET_DAY: "All Days",
        PER_DAY: "Per Day",
    },
    ADD_ON_STEP_LABELS: {
        BASIC_INFO: "Basic Information",
        IMAGE: "Add-on Image",
        PRICING: "Add-on Pricing",
        DEPOSIT: "Add-on pre-trip deposit",
    },
    LISTING_MUTATIONS: {
        DUPLICATE_LISTING_SUCCESS: "Listing duplicated successfully",
        DUPLICATE_LISTING_ERROR: "Failed to duplicate listing",
        DELETE_LISTING_SUCCESS: "Listing deleted successfully",
        DELETE_LISTING_ERROR: "Failed to delete listing",
    },
    LISTING_MANAGE: {
        LISTING_BASICS: "Listing basics",
        AVAILABILITY: "Availability",
        PRICING: "Pricing",
        PHOTOS: "Photos",
        LOCATION: "Location",
        SPECIES: "Species",
        AMENITIES: "Amenities",
        ADD_ONS: "Add-ons",
        POST_BOOKING_PAYMENTS: "Post-booking payments",
        GUIDE: "Guide",
        BACK_TO_OUTFITTER: "Back to outfitter",
        DUPLICATE_LISTING_CONFIRMATION:
            "Are you sure to duplicate this listing?",
        DELETE_LISTING_CONFIRMATION: "Are you sure to delete this listing?",
        DUPLICATING: "Duplicating",
        DELETING: "Deleting",
        DELETE_LISTING_WARNING_ITEM_1:
            "The listing will be unpublished and will not show up in search results or Mallard Bay website",
        DELETE_LISTING_WARNING_ITEM_2:
            "All bookings associated with this listing will not be affected and will remain under your account",
        DELETE_LISTING_WARNING_ITEM_3:
            "You will no longer have access to view or edit the listing. This action can not be undone.",
    },
    LISTING_MANAGE_MENU: {
        COPY_LINK: "Copy link",
        DELETE_LISTING: "Delete listing",
        PREVIEW_LISTING: "Preview listing",
        DUPLICATE_LISTING: "Duplicate listing",
    },
    PRICE: "Price",
    DEPOSIT_AMOUNT: "Deposit amount",
    DEPOSIT_PERCENTAGE: "Deposit percentage",
    APPLYING_TO_ALL_LISTINGS: "Applying to all listings",
    ASSIGNED_TO: "Assigned to",
    SPECIES: "Species",
    ATTENTION: "Attention",
    SELECT_COLOR: "Select color",
    OPEN_COLOR_PICKER: "Open color picker",
    CLEAR_CUSTOM_COLOR: "Clear custom color",
    ERROR_SETTING_CUSTOM_COLOR: "Error setting custom color",
    CHANGE_COLOR: "Change color",
    IMPORT_OUTFITTER: "Import an Outfitter from Hubspot",
    SEARCH_HUBSPOT_COMPANIES: "Search Hubspot companies",
    NO_HUBSPOT_COMPANIES_FOUND: "No Hubspot companies found",
    IMPORT: "Import",
    ERROR: "Error!",
    ERROR_LABEL: "Error",
    ERROR_FROM_STRIPE: "Error from Stripe",
    IMPORTED_OUTFITTER: "Imported Outfitter",
    APPROVED: "Approved",
    SUSPENDED: "Suspended",
    PENDING_APPROVAL: "Pending approval",
    OWNER: "Owner",
    DELETE_OUTFITTER: "Delete outfitter",
    ADDRESS: "Address",
    LINK_COPIED: "Link copied",
    UNAPPROVED: "Unapproved",
    WEBSITE: "Website",
    NO_WEBSITE: "No website set",
    CONTACT_EMAIL: "Contact Email",
    NO_CONTACT_EMAIL: "No contact email",
    DEFAULT_CHECK_IN: "Default Check-in",
    DEFAULT_CHECK_OUT: "Default Checkout",
    NONE_SET: "None set",
    SHARE_LINK: "Share link",
    AM: "AM",
    PM: "PM",
    SPECIES_MANAGE: {
        ADD_SPECIES: "Add species",
        EDIT_SPECIES: "Edit species",
        DELETE_SPECIES: "Delete species",
        HUNTING_SPECIES: "Hunting species",
        FISHING_SPECIES: "Fishing species",
        DELETED_SPECIES: "Deleted species",
        DELETE_SPECIES_SUCCESS: "Species deleted successfully",
        DELETE_SPECIES_ERROR: "Failed to delete species",
        DELETE_SPECIES_CONFIRMATION: "Are you sure to delete this species?",
    },
    DELETE_OUTFITTER_CONFIRMATION: "Are you sure to delete this outfitter?",
    DELETE_OUTFITTER_SUCCESS: "Outfitter deleted successfully",
    DELETE_OUTFITTER_ERROR: "Failed to delete outfitter",
    NONE_ADDED: "None added",
    EDIT_CONTACT: "Edit contact",
    UPDATE_CONTACT: "Update contact",
    CONTACTS: "Contacts",
    ADD_CONTACT: "Add contact",
    NO_CONTACTS_ADDED_YET: "No contacts added yet",
    ADD_A_CONTACT: "Add a contact",
    CREATE_CONTACT: "Create contact",
    OUTFITTER_MANAGE: {
        GENERAL: "General",
        STATS: "Stats",
        LISTINGS: "Listings",
        TEAM_MEMBERS: "Team members",
        PHOTOS: "Photos",
        VIDEO: "Video",
        LODGING: "Lodging",
        ADD_ONS: "Add-ons",
        POST_BOOKING_PAYMENTS: "Post-booking payments",
        REVIEWS: "Reviews",
        WIDGETS: "Widgets",
        COUPONS: "Coupons",
        CANCELLATION_POLICY: "Cancellation policy",
        PAYMENT_RATES: "Payment rates",
        PAYMENT_SETTINGS: "Payment settings",
        CONTACTS: "Contacts",
        ADMIN_NOTES: "Admin notes",
    },
    ADD_CUSTOM_POST_BOOKING_PAYMENT: "Add custom post-booking payment",
    NO_POST_BOOKING_PAYMENTS: "No post-booking payments available",
    ADDRESS_INPUT_PLACEHOLDER: "Start typing an address to get suggestions",
    NO_RESULTS: "No results",
    NO_RESULTS_FOUND: "No results found",
    INVOICE_NOTE: "Invoice Note",
    SPORTSMAN_NAME: "Sportsman Name",
    SPORTSMAN_EMAIL: "Sportsman Email",
    SPORTSMAN_EMAIL_PLACEHOLDER: "sportsman@gmail.com",
    HUBSPOT_DEAL_OWNER_EMAIL_PLACEHOLDER: "youruser@mallardbay.com",
    ATTRIBUTION_FORM_LABELS: {
        SPORTSMAN_EMAIL: "Sportsman Email",
        HUBSPOT_DEAL_OWNER_EMAIL: "HubSpot Deal Owner Email",
        DEAL_ATTRIBUTION: "How did this customer request a quote?",
    },
    ADDITIONAL_ADD_ONS: "Additional Add-ons",
    ADDITIONAL_ADD_ONS_PROMPT:
        "Please select the any additional add-ons that need to be captured",
    PROCESSING_FEES_ADDED: "Processing fees will be added",
    SPORTSMAN: "Sportsman",
    OUTFITTER: "Outfitter",
    OUTFITTERS: "Outfitters",
    RESPONSIBLE_FOR_FEES: "is responsible for processing fees.",
    CARD: "Card",
    BANK: "Bank (ACH)",
    MAX: "max",
    QUANTITY: "Quantity",
    CHARGE_CARD: "Charge card",
    CHARGES_A_CARD_THAT_THE_SPORTSMAN_HAS_SAVED:
        "Charges a card that the sportsman has saved",
    BANK_CARD: "Bank/card",
    INITIATED: "Initiated",
    EST_ARRIVAL: "Est. Arrival",
    PAYOUT: {
        PAYOUTS: "Payouts",
        NO_PAYOUTS: "No payouts",
        STRIPE_PAYOUT_ID: "Stripe payout ID",
        DELIVERY_METHOD: "Delivery method",
        PAY_TO: "Pay to",
        TRANSACTIONS: "Transactions",
        TIMELINE: "Timeline",
        PAYOUT_TIMELINE_HELPER_TEXT_1: "Don’t see your payout?",
        PAYOUT_TIMELINE_HELPER_TEXT_2:
            "Banks can take longer than expected to process payouts. Waiting 1 or 2 business days solves most issues.",
    },
    REMAINING: "Remaining",
    UNPAID_BALANCES: "Unpaid Balances",
    PLEASE_SELECT_THE_UNPAID_BALANCES_THAT_NEED_TO_BE_CAPTURED:
        "Please select the unpaid balances that need to be captured",
    PAID: "Paid",
    REQUESTED: "Requested",
    REFUNDS_ISSUED: "Refunds Issued",
    SUCCESS: "Success!",
    PAYMENT_TOTAL_AFTER_EXISTING_REFUNDS:
        "Payment total after existing refunds:",
    NO_REFUNDS: "No refunds issued",
    INVOICE: "Invoice",
    IS_THIS_AN_INVOICE: "Is this an invoice?",
    INVOICE_ALERT_BOX_NOTE:
        "By selecting this is an invoice, we will generate a PDF invoice to include in the automated email. To use this feature, you must provide the sportsman's name and email address. Additionally, the details in this quote will be locked, and the sportsman will not be able to make any changes once the quote is created.",
    NO_PAYMENTS_FOUND: "No payments found",
    NO_PAYMENTS_MADE_YET: "No payments made yet!",
    SETTLED: "Settled",
    MARK_AS_RECIEVED_IN_PERSON: "Mark as received in person",
    VIEW_INVOICE: "View invoice",
    COPIED_TO_CLIPBOARD: "Copied to clipboard!",
    RESEND_INVOICE: "Resend invoice",
    COPY_LINK: "Copy link",
    ACTIVATE: "Activate",
    DEACTIVATE: "Deactivate",
    SUCCESSFULLY_SENT_INVOICE: "Successfully sent invoice!",
    SUCCESSFULLY_UPDATED_INVITE: "Successfully updated invite!",
    PENDING: "Pending",
    CANCELED: "Canceled",
    CANCEL_UNPAID_BALANCE: "Cancel unpaid balance",
    ACTIVATE_UNPAID_BALANCE: "Activate unpaid balance",
    CANCEL_THIS_UNPAID_BALANCE: "Cancel this unpaid balance?",
    CANCEL_UNPAID_BALANCE_NOTE: "This will cancel the unpaid balance.",
    REACTIVATE_UNPAID_BALANCE: "Re-Activate unpaid balance?",
    REACTIVATE_UNPAID_BALANCE_NOTE: "This will re-activate the unpaid balance.",
    NO_UNPAID_BALANCES: "No unpaid balances",
    CANCELED_UNPAID_BALANCE: "Canceled unpaid balance",
    ACTIVATED_UNPAID_BALANCE: "Activated unpaid balance",
    HIDE_FROM_SPORTSMAN: "Hide from Sportsman at checkout",
    HIDE_FROM_SPORTSMAN_LABEL: "Hide from Sportsman at checkout?",
    OUTFITTER_CREATED_SUCCESSFULLY: "Outfitter created successfully",
    OUTFITTER_UPDATED_SUCCESSFULLY: "Outfitter updated successfully",
    HIDE_PACKAGE_FROM_SPORTSMAN_NOTE:
        "Turn this on if you want to make this package visible only to you and your staff when sending quotes/invoices, and not visible to sportsman during checkout.",
    HIDDEN: "Hidden",
    YOUR_OUTFITTER_WILL_BE_LIVE_AT: "Your outfitter will be live at",
    THIS_NAME_IS_ALREADY_IN_USE: "This name is already in use",
    ADMIN_ONLY: "Admin only",
    CREATE_OUTFITTER: "Create outfitter",
    OUTFITTER_CARD_FORM_LABELS: {
        NAME: "Outfitter name",
        EMAIL: "Contact email",
        ABOUT: "Outfitter description",
        WEBSITE: "Website",
        AVATAR_URL: "Logo",
        CHECK_IN_TIME: "Default check-in time",
        CHECK_OUT_TIME: "Default check-out time",
        GMB_ID: "Google My Business ID",
        HUBSPOT_ID: "Hubspot Company ID",
        IS_GUIDE_TECH: "Is outfitter GuideTech Pro",
    },
    GUIDE_TECH_PRO: "GuideTech Pro",
    GUIDE_TECH_LITE: "GuideTech Lite",
    CLICK_TO_LEARN_MORE_ABOUT_GUIDE_TECH:
        "Click here to learn more about GuideTech Pro",
    VIEW_QR_CODE: "View QR code",
    RESOLVE: "Resolve",
    INVALID_SLUG: "Invalid slug",
    TOP_LISTINGS_SECTION_TITLE: "Top listings section title",
    TOP_OUTFITTERS_SECTION_TITLE: "Top outfitters section title",
    OTHER_LISTINGS_SECTION_TITLE: "Other listings section title",
    OTHER_OUTFITTERS_SECTION_TITLE: "Other outfitters section title",
    FEATURED_LISTINGS: "Featured listings",
    FEATURED_OUTFITTERS: "Featured outfitters",
    OTHER_LISTINGS: "Other listings",
    OTHER_OUTFITTERS: "Other outfitters",
    CREATE_PRICING_PACKAGE: "Create pricing package",
    CREATE_ADD_ON: "Create add on",
    DEPOSITS: "Deposits",
    DAYS_BEFORE_BALANCE_DUE: "Days before balance due",
    DAYS: "days",
    DATE_BALANCE_DUE: "Date balance due",
    EDIT_PAYMENT_SETTINGS: "Edit payment settings",
    RESET: "Reset",
    CLEAR: "Clear",
    CUSTOM_STATEMENT_DESCRIPTOR: "Custom statement descriptor",
    PREVIOUS_MONTH: "Previous Month",
    NEXT_MONTH: "Next Month",
    IS_PROCESSING_PAYMENT: "Is Mallard Bay collecting the payment?",
    PAYMENT_INFO: "Payment info",
    HAS_RECEIVED_PAYMENT: "Has payment been received?",
    DEPOSIT_DATE: "Deposit date",
    PAYMENT_METHOD: "Payment method",
    CHECK_NUMBER: "Check number",
    EDIT_OFF_PLATFORM_BOOKING: "Edit off-platform booking",
    CREATE_BOOKING: "Create booking",
    MARK_PAYMENT_AS_PAID_OFFLINE_SUCCESS:
        "Successfully marked payment as paid.",
    MARK_AS_NOT_NEEDING_REFUND_SUCCESS:
        "Successfully marked payment as not needing refund.",
    CANCEL_PAYMENT_SUCCESS: "Successfully canceled payment.",
    PAY_OUTFITTER_SUCCESS: "Successfully paid outfitter.",
    MARK_AS_PAID: "Mark as paid",
    PAY_OUTFITTER: "Pay outfitter",
    OUTFITTER_PAID: "Outfitter Paid",
    CANCEL_PAYMENT: "Cancel payment",
    CANCEL_PAYMENT_CONFIRMATION_MESSAGE:
        "Are you sure you want to cancel this payment?",
    PAY_OUTFITTER_CONFIRMATION_MESSAGE_PREFIX: "When you initiate a payout,",
    PAY_OUTFITTER_CONFIRMATION_MESSAGE_SUFFIX:
        "will be transferred to the outfitter's connected Stripe account. Please ensure that the sportsman's payment has cleared in our account before proceeding. Are you certain you want to payout the outfitter?",
    CHANGE_DUE_DATE: "Change due date",
    CHANGE_DUE_DATE_SUCCESS: "Successfully changed payment due date.",
    PAYMENT_DETAILS: "Payment Details",
    PAYMENT_METHODS: "Payment Methods",
    ALLOW_PAY_WITH_CHECK:
        "Would you like to allow the sportsmen to pay by check?",
    PAYMENT_ID: "Payment ID",
    DUE_DATE: "Due Date",
    PROCESSING_FEE: "Processing Fee",
    DEPOSIT_DATE_CAPITALIZED: "Deposit Date",
    CHECK_NUMBER_CAPITALIZED: "Check Number",
    SORT: "Sort",
    SEARCH: "Search",
    REQUIRED_FIELD: "Required field",
    ENTER_CORRECT_EMAIL: "Enter correct email",
    ENTER_CORRECT_PHONE: "Enter correct phone",
    REQUIRED: "Required",
    IN_PROGRESS: "In Progress",
    OUTFITTER_DEFAULT: "Outfitter default",
    NOT_ACTIVE_ON_LISTINGS: "Not active on any listings",
    ACTIVE_ON: "Active on",
    ADD_ON_ENABLED_BUT_NOT_ASSIGNED:
        "This add-on is enabled but is not assigned to any listings.",
    LISTING_TYPE: "Listing type",
    PLEASE_SELECT_LISTING_TYPE:
        "Please select which type of listing you will provide.",
    HUNTING: "Hunting",
    FISHING: "Fishing",
    ALL_OUTFITTERS: "All outfitters",
    SEARCH_ALL_OUTFITTERS: "Search all outfitters",
    NO_OUTFITTERS_YET: "No outfitters yet",
    CLICK: "Click",
    TO_GIVE_SOMETHING_TO_BOOK: "to give your customers something to book!",
    CREATE_NEW_LISTING: "Create new listing",
    SUCESSFULLY_CREATED_LISTING: "Successfully created listing",
    FAILED_TO_CREATE_LISTING: "Failed to create listing",
    LISTING_BASICS: "Listing basics",
    BLAST_AND_CAST: "Cast & Blast",
    UNSET: "Unset",
    NOT_SET: "Not set",
    LICENSE_REQUIRED: "License required",
    CHECK_IN_TIME: "Check-in Time",
    CHECK_OUT_TIME: "Checkout Time",
    SUCCESSFULLY_UPDATED_LISTING: "Successfully updated listing",
    FAILED_TO_UPDATE_LISTING: "Failed to update listing",
    LISTING_BASICS_FORM_LABELS: {
        PUBLISHED: "Status",
        TITLE: "Title",
        DESCRIPTION: "Description",
        RULE: "Rules",
        LICENSE_REQUIRED: "Does this listing require a license from sportsmen?",
        MIN_GUEST: "Minimum guests",
        MAX_GUEST: "Maximum guests",
        CHECK_IN_MINUTES: "Check-in time",
        CHECK_OUT_MINUTES: "Checkout time",
        LICENSE_INFO: "Select license state",
    },
    LISTING_BASICS_FORM_SUB_LABELS: {
        PUBLISHED: "What is the status of this listing?",
        TITLE: "Give your listing an appealing title to attract sportsman",
        DESCRIPTION:
            "Give sportsman a sense of what it's like at your property, what to expect and why they'll love the trip",
        RULE: "Provide any extra details for sportsman booking your trip",
        MIN_GUEST: "The minimum amount of guests required to book",
        MAX_GUEST: "The maximum amount of guests allowed to book",
        CHECK_IN_MINUTES: "How long should sportsmen check in?",
        CHECK_OUT_MINUTES: "How long should sportsmen check out?",
    },
    US_LICENSES: "US Licenses",
    CANADA_LICENSES: "Canada Licenses",
    CANCEL_BOOKING: "Cancel booking",
    CANCEL_BOOKING_SUCCESS: "Successfully canceled booking!",
    CANCEL_BOOKING_CONFIRMATION_TITLE: "Are you sure you want to cancel?",
    CANCEL_BOOKING_CONFIRMATION_MESSAGE:
        "By canceling this booking, all pending payments will be automatically canceled and the availability on your calendar will be restored. Please fill out the form below to select a reason for the cancellation and issue any needed refunds.",
    MARK_AS_REFUNDED: "Mark as refunded",
    DOES_NOT_NEED_REFUND: "Does not need a refund",
    MARK_AS_REFUNDED_CONFIRMATION_TITLE:
        "Are you sure you want to mark as refunded?",
    MARK_AS_REFUNDED_CONFIRMATION_MESSAGE:
        "Before marking this payment as refunded, please ensure you have manually processed the refund. This action will only record the payment as refunded and will not initiate an actual refund.",
    MARK_AS_REFUNDED_SUCCESS: "Successfully marked payment as refunded!",
    REFUND_STATUS: "Refund Status",
    REFUNDED_OFFLINE: "Refunded offline",
    OFFLINE_REFUND_NEEDED: "Offline refund needed",
    CUSTOM_BOOKING_FEE: "Custom booking fee",
    TOTAL_AMOUNT: "Total amount",
    USE_CUSTOM_BOOKING_FEE: "Use custom booking fee?",
    BOOKING_DATE: "Booking Date",
    BOOKING_DATE_SUB_LABEL: "Date trip was booked",
    TRIP_DATE: "Trip Date",
    TRIP_DATE_SUB_LABEL: "Date trip begins",
    CHOOSE_METRIC_DATE_PROP: "Choose how to group data",
    THIS_YEAR: "This Year",
    LAST_YEAR: "Last Year",
    THIS_MONTH: "This Month",
    REPORTING: {
        LABEL: "Reporting",
        REPORTING_OVERVIEW: "Reporting overview",
        VIEW_FULL_REPORT: "View full report",
        BUSINESS_PERFORMANCE: "Business Performance",
        BUSINESS_PERFORMANCE_DEFINITION:
            "Summary of your overall business performance on Mallard Bay, including key metrics like gross bookings, average bookings, and revenue forecast",
        PAYOUTS_DEFINITION:
            "The Payouts report shows all payments that have been initiated to your Stripe Connected account and transferred to your bank account.",
        PAYMENTS_DEFINITION:
            "The Payments report details all transactions for your outfitter, including those linked to initial bookings and any additional payment requests.",
        UNPAID_BALANCES_DEFINITION:
            "The Unpaid Balances report lists all transactions that have not yet been processed, such as add-ons with payment plans. Once processed, these transactions will appear on the Payments report.",
    },
    ALL_STATUSES: "All statuses",
    OFFLINE: "Offline",
    NO_REFERRALS_FOUND: "No referrals found",
    SENT_TO: "Sent to",
    RECEIVED_FROM: "Received from",
    DATE: "Date",
    BOOKING_TOTAL: "Booking total",
    REFERRAL_FEE: "Referral Fee",
    REFERRAL_FEE_RATE: "Referral Fee Rate",
    REFERRALS: "Referrals",
    TBD: "TBD",
    MALLARD_BAY: "Mallard Bay",
    CREATE_REFERRAL: "Create Referral",
    NO_REQUESTED_BOOKINGS: "No requested bookings found.",
    PLEASE_SELECT_BOOKING: "Please select a booking to continue.",
    PLEASE_SELECT_OUTFITTER: "Please select an outfitter to send referral to.",
    DESCRIPTION_REQUIRED: "Description is required.",
    SPORTSMAN_NAME_REQUIRED: "Sportsman name is required.",
    SPORTSMAN_EMAIL_REQUIRED: "Sportsman email is required.",
    REVIEW: "Review",
    CREATE_REFERRAL_SUCCESS: "Successfully created booking referral!",
    DECLINE_REFFERAL_SUCCESS: "Successfully declined booking referral!",
    ACCEPT_REFERRAL_SUCCESS: "Successfully accepted booking referral!",
    CANCEL_REFERRAL_SUCCESS: "Successfully cancelled booking referral!",
    SAVING_REFERRAL: "Saving referral...",
    REFERRAL_FEE_NOTE:
        "Once the outfitter has accepted your referral and the sportsman has made their booking, we will issue your referral fee once we have collected sufficient funds from the sportsmen to cover it.",
    ACCEPTED_REFERRAL_FEE_NOTE:
        "We will issue your referral fee once we have collected sufficient funds from the sportsmen to cover it.",
    BOOKING_REFERRALS: "Booking Referrals",
    SENT_BOOKING_REFERRALS: "Sent Booking Referrals",
    RECEIVED_BOOKING_REFERRALS: "Received Booking Referrals",
    THE_OUTFITTER: "the outfitter",
    HAVE_QUESTIONS: "Have questions?",
    REFERRAL_FORM_LABELS: {
        IS_BY_ADMIN: "Is this an admin referral?",
        IS_WITH_BOOKING: "Are you creating a referral for an existing booking?",
        DESCRIPTION_WITH_BOOKING:
            "Please provide any additional details that the Outfitter may need to make a decision on the referral.",
        DESCRIPTION_WITHOUT_BOOKING:
            "Please provide any relevant details that the Outfitter will need to make a decision on the referral (e.g., dates, desired species to hunt/fish, lodging, etc.).",
        BOOKING: "Which booking would you like to refer?",
        OUTFITTER: "Which outfitter are you creating this referral for?",
        SPORTSMAN_NAME: "Sportsman name",
        SPORTSMAN_EMAIL: "Sportsman email",
    },
    REFERRAL_ADMIN_NOTE: "This will create a referral with $0 referral fee.",
    REFERRAL_NO_BOOKING_NOTE:
        "By creating a referral without a booking, you will need to provide the Outfitter with relevant details about the trip. We will calculate your referral fee once the trip is booked.",
    WITH_BOOKING: "With booking",
    WITHOUT_BOOKING: "Without booking",
    CONFIRM_DECLINE_REFERRAL_TITLE:
        "Are you sure you want to decline this referral?",
    DECLINE_REFERRAL: "Decline referral",
    CONFIRM_ACCEPT_REFERRAL_TITLE: "Accept booking referral",
    CONFIRM_ACCEPT_REFERRAL_MESSAGE:
        "Are you sure you want to accept this referral?",
    ACCEPT_REFERRAL: "Accept referral",
    CONFIRM_CANCEL_REFERRAL_TITLE: "Cancel booking referral",
    CONFIRM_CANCEL_REFERRAL_MESSAGE:
        "Are you sure you want to cancel this referral?",
    MARK_AS_NOT_NEEDING_REFUND_TITLE: "Mark payment as not needing refund",
    MARK_AS_NOT_NEEDING_REFUND_MESSAGE:
        "Are you sure you want to mark this payment as not needing a refund?",
    CANCEL_REFERRAL: "Cancel referral",
    DENIED: "denied",
    REFERRAL: "referral",
    DECLINED: "declined",
    PLEASE_SELECT_THE_REASON: "Please select the reason this",
    IS_BEING: "is being",
    ADD_A_TEAM_MEMBER: "Add a team member",
    ADD_TEAM_MEMBER: "Add team member",
    EDIT_TEAM_MEMBER: "Edit team member",
    UPDATE_TEAM_MEMBER: "Update team member",
    NO_TEAM_MEMBERS_YET: "No team members yet",
    LISTING_TYPES: {
        HUNTING: "Hunting",
        FISHING: "Fishing",
        BLAST_CAST: "Blast & Cast",
    },
    SEND_QUOTE: "Send Quote",
    INBOX: "Inbox",
    HOME: "Home",
    ROLE: "Role",
    CREATE_TEAM_MEMBER_SUCCESS: "Successfully added team member!",
    UPDATE_TEAM_MEMBER_SUCCESS: "Successfully updated team member!",
    DELETE_TEAM_MEMBER_SUCCESS: "Successfully deleted team member!",
    DELETE_TEAM_MEMBER_TITLE: "Delete team member",
    DELETE_TEAM_MEMBER_MESSAGE:
        "Are you sure you want to delete this team member?",
    EXPORT_DATA: {
        LABEL: "Export",
        SELECT_DATA_TYPE: "Select data type",
        INVALID_DATA_TYPE: "Invalid data type selected",
        SUCCESS_MESSAGE_PAYMENTS: "Successfully exported payments",
        SUCCESS_MESSAGE_UNPAID_BALANCES:
            "Successfully exported unpaid balances",
        NOTE_PAYMENTS_EXPORT:
            "Export payments for the selected outfitter and download them as a CSV file.",
        NOTE_UNPAID_BALANCES_EXPORT:
            "Export unpaid balances for the selected outfitter and download them as a CSV file.",
        DUE_AFTER: "Due after",
        DUE_BEFORE: "Due before",
        CREATED_AFTER: "Created after",
        CREATED_BEFORE: "Created before",
    },
    GUIDE: "Guide",
    NO_GUIDE_YET: "No guide yet.",
    NO_BOOKING_GUIDE_YET: "A guide has not been assigned to this trip.",
    NO_GUIDES_AVAILABLE:
        "No guides available. Please add team members with a 'guide' role to the outfitter.",
    EDIT_GUIDE: "Edit Guide",
    QUOTES: "Quotes",
    NEW_COUPON: "New coupon",
    SITE_WIDE_COUPON_NOTE: "Leave blank to create a site wide coupon",
    EXPIRATION_DATE: "Expiration Date",
    AVAILABILITY_BANNER: {
        CURRENTLY: "currently",
        DO: "do",
        DOES: "does",
        DESCRIPTION_1: "not have any availability.",
        DESCRIPTION_2: "Please open the calendar to add available dates.",
    },
    ADMIN_REFERRAL: "Admin referral",
    FOR_MORE_ADD_ON_INFO_PREFIX:
        "For more information and examples, please see",
    FOR_MORE_ADD_ON_INFO_LINK_TEXT: "this article",
    PERIOD: ".",
    CLOSE_DRAWER: "Close drawer",
    UPDATE_BOOKING_PAYMENT_PLAN_NOTE:
        "Note: Editing a booking using a deposit payment plan within 14 days of the trip may schedule a payment past the start date of the booking.",
    POLICY_DESCRIPTION_PLACEHOLDER: "Describe your policy here...",
    EDIT_POLICY: "Edit policy",
    REFUND: "Refund",
    MARK_AS_REFUND_NEEDED: `Mark as "Refund needed"`,
    UNABLE_TO_ISSUE_AUTO_REFUND: `We are unable to issue an automatic refund for this sportsman. They either paid offline or used an alternate payment method. By selecting "Mark as refund needed", we will flag the payments as needing a manual refund. Once the refund has been manually issued, go to the payments section of the booking and choose "Mark as refunded".`,
    AMOUNT_PAID: "Amount paid",
    NO_DEPOSIT_NOTE:
        "There was no deposit payment because the sportsman paid in full.",
    CHOOSE_HOW_MUCH_TO_REFUND: "Choose how much to refund each sportsman.",
    NO_REFUNDS_AVAILABLE: "No refunds available.",
    CANCELLATION_NOTE_LABEL: "Additional notes about cancellation.",
    OUTFITTER_POLICY: "Outfitter cancellation policy",
    YOUR_POLICY: "Your cancellation policy",
    UPLOAD_VIDEO: "Upload video",
    EDIT_VIDEO: "Edit Video",
    CONTACT_NUMBER_NOT_AVAILABLE: "Contact number not available.",
} as const

export default COPY_CONSTANTS
