import queryString from "query-string"
import { useToast } from "@chakra-ui/react"
import slugify from "slugify"
import { navigate } from "gatsby"

import type {
    LicenseInfoFieldsFragment,
    AmenityFieldsFragment,
    UpdateListingInput,
    UpdateOutfitterInput,
    MinimalOutfitterFieldsFragment,
    ListingFieldsFragment,
    UserFieldsFragment,
} from "~graphql/generated/graphql"
import {
    useUpdateListingMutation,
    useUpdateOutfitterMutation,
    OutfitterStatus,
} from "~graphql/generated/graphql"
import type { NumberFields } from "~utils/types"

export * from "./booking-helpers"

export function sortAmenitiesByName(
    amenityA: AmenityFieldsFragment,
    amenityB: AmenityFieldsFragment
): number {
    if (amenityA.name > amenityB.name) return 1
    if (amenityA.name < amenityB.name) return -1
    return 0
}

export function sortLicensesByState(
    licenseA: LicenseInfoFieldsFragment,
    licenseB: LicenseInfoFieldsFragment
): number {
    if (licenseA.state > licenseB.state) return 1
    if (licenseA.state < licenseB.state) return -1
    return 0
}

export function filterApprovedOutfitters(
    outfitters: MinimalOutfitterFieldsFragment[]
): MinimalOutfitterFieldsFragment[] {
    return outfitters.filter(
        (outfitter) => outfitter.status === OutfitterStatus.Approved
    )
}

export function filterPublishedListings(
    listings: ListingFieldsFragment[]
): ListingFieldsFragment[] {
    return listings.filter((listing) => listing.published)
}

export function filterListingsWithoutAvailability(
    listings: ListingFieldsFragment[]
): ListingFieldsFragment[] {
    return listings.filter((listing) => !listing.next_availability)
}

export function filterListingsByOutfitterId({
    listings,
    filterByOutfitterId,
}: {
    listings: ListingFieldsFragment[]
    filterByOutfitterId: string
}) {
    return listings.filter(
        (listing) => listing.outfitter.id === filterByOutfitterId
    )
}

export function useListingMutation({
    id,
    data,
    onCompleted,
}: {
    id: string
    data: UpdateListingInput
    onCompleted?: () => void
}) {
    const toast = useToast()

    const [updateListing, { loading: isUpdating }] = useUpdateListingMutation({
        variables: { id, data },
        onCompleted,
        onError: (error: { message: string }) => {
            toast({
                position: "top",
                title: "Error",
                description: error.message,
                status: "error",
                duration: 8000,
                isClosable: true,
            })
        },
    })

    return { updateListing, isUpdating }
}

export function useOutfitterMutation({
    id,
    data,
    onCompleted,
}: {
    id: string
    data: UpdateOutfitterInput
    onCompleted?: () => void
}) {
    const toast = useToast()

    const [updateOutfitter, { loading: isUpdating }] =
        useUpdateOutfitterMutation({
            variables: { id, data },
            onCompleted,
            onError: (error: { message: string }) => {
                toast({
                    position: "top",
                    title: "Error",
                    description: error.message,
                    status: "error",
                    duration: 8000,
                    isClosable: true,
                })
            },
        })

    return { updateOutfitter, isUpdating }
}

export function stripTypenameFromObject<
    ObjectType extends { __typename?: string },
>(obj: ObjectType): ObjectType {
    const newObj = { ...obj }
    delete newObj.__typename
    return newObj
}

export const getUrlQueryParamsObj = (): queryString.ParsedQuery => {
    if (isServerSide()) {
        return {}
    }
    const { search } = window.location
    return queryString.parse(search)
}

export function navigateToSearchParams<
    TParams extends Record<string, string | number>,
>(newParams: TParams) {
    const queryParams = getUrlQueryParamsObj()

    const params = queryString.stringify({
        ...queryParams,
        ...newParams,
    })

    void navigate(`?${params}`, { replace: true })
}

export function getUrlQueryParamInt(
    paramName: string,
    defaultParamValue: string
) {
    const param = getUrlQueryParam(paramName)

    return parseInt(param || defaultParamValue, 10)
}

export function getUrlQueryParam(paramName: string) {
    const paramsObj = getUrlQueryParamsObj()

    const param = paramsObj[paramName]
    const isParamValid = typeof param === "string"

    return isParamValid ? param : null
}

function isServerSide() {
    return typeof window === "undefined"
}

// Returns an array of arrays (of length boolChecks.length + 1)
// split by the consecutive filters (boolChecks) passed in
// The last array is the items that don't satisfy any of the filters
// Ex. splitArrayWithFilters([1,2,3,4,5], [i => i < 3, i => i > 3]) => [[1,2],[4,5],[3]]
export const splitArrayWithFilters = <T>(
    array: T[],
    boolChecks: ((item: T) => boolean)[]
): T[][] => {
    const retArr: T[][] = [...boolChecks.map(() => []), []]

    array.forEach((item) => {
        const index = boolChecks.findIndex((check) => check(item))
        if (index === -1) {
            retArr[retArr.length - 1].push(item)
        } else {
            retArr[index].push(item)
        }
    })

    return retArr
}

// Adds or removes an item from an array depending on if it exists (or passes custom bool check)
export const adjustArrayWithItem = <T>(
    array: T[],
    item: T,
    boolCheck?: (item: T) => boolean
): T[] => {
    if (
        boolCheck
            ? array.some((arrayItem) => boolCheck(arrayItem))
            : array.includes(item)
    ) {
        return array.filter((arrayItem) =>
            boolCheck ? !boolCheck(arrayItem) : arrayItem !== item
        )
    }

    return [...array, item]
}

// Removes duplicate items from an array, pass in a field for objects (usually id)
export const dedupeArray = <T>(
    arrayToDedupe: T[],
    fieldToCheck?: keyof T
): T[] => {
    if (fieldToCheck) {
        return arrayToDedupe.filter(
            (item, idx, array) =>
                idx ===
                array.findIndex(
                    (otherItem) =>
                        otherItem[fieldToCheck] === item[fieldToCheck]
                )
        )
    }
    return arrayToDedupe.filter(
        (item, idx, array) => idx === array.indexOf(item)
    )
}

// Sorts an array by the created_at field, with the newest first
export const sortNewestLast = <T extends { created_at: number }>(
    array: T[]
): T[] => [...array].sort((itemA, itemB) => itemA.created_at - itemB.created_at)

export const getUTCNoon = (date: Date): number => {
    return Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        12,
        0,
        0
    )
}

// Groups array based on a generic string check. Matching strings will be grouped.
// i.e. group listings by outfitter
// e.g. groupArray(listings, listing => listing.outfitter.id)
export const groupArray = <T>(
    array: T[],
    groupCheck: (item: T) => string
): { [key: string]: T[] } => {
    const grouped: { [key: string]: T[] } = {}

    array.forEach((item) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- TODO fix eslint\
        if (grouped[groupCheck(item)]) {
            grouped[groupCheck(item)].push(item)
        } else {
            grouped[groupCheck(item)] = [item]
        }
    })

    return grouped
}

export const sortAscOrDesc = <T extends Record<string, unknown>>(
    array: T[],
    field: NumberFields<T>,
    isAsc = false
): T[] =>
    [...array].sort((itemA, itemB) =>
        isAsc
            ? (itemA[field] as number) - (itemB[field] as number)
            : (itemB[field] as number) - (itemA[field] as number)
    )

// Updating item at array in index
export const updateItemAtIndex = <T>(
    array: T[],
    item: T,
    index: number
): T[] => {
    return [...array.slice(0, index), item, ...array.slice(index + 1)]
}

export const getUserName = (
    user: UserFieldsFragment | null | undefined
): string => `${user?.first_name} ${user?.last_name}`

export function getArrayOfCount(length: number): number[] {
    return Array.from({ length })
        .fill(null)
        .map((_, index) => index)
}

export function splitArrayAtIndex<TItem>(array: TItem[], breakAtIndex: number) {
    return {
        first: array.slice(0, breakAtIndex),
        second: array.slice(breakAtIndex),
    }
}

export function isNil(value: unknown): value is null | undefined {
    return value === null || value === undefined
}

export function groupByKey<TItem>(array: TItem[], key: keyof TItem) {
    return array.reduce(
        (groupedObject, currentItem) => {
            const currentKey = currentItem[key] as string

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- TODO fix eslint
            if (!groupedObject[currentKey]) {
                groupedObject[currentKey] = []
            }

            groupedObject[currentKey].push(currentItem)

            return groupedObject
        },
        {} as Record<string, TItem[]>
    )
}

// Used to conditionally spread objects for updates / creates
// Replaces ...(value ? { key: value } : {})
export function ifDefined(
    obj: Record<string, unknown>
): Record<string, unknown> {
    return Object.fromEntries(
        Object.entries(obj).filter(([, value]) => {
            return value !== undefined
        })
    )
}

export async function getAndOpenPdfInNewTab({
    getPdfFunc,
}: {
    getPdfFunc: () => Promise<string | null>
}) {
    const pdf = await getPdfFunc()

    if (!pdf) return

    openPdfInNewTab(pdf)
}

function openPdfInNewTab(base64: string) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- TODO fix eslint
    const buffer = Buffer.from(base64 ?? "", "base64")
    const blob = new Blob([buffer], {
        type: "application/pdf",
    })
    const objectUrl = URL.createObjectURL(blob)

    // There's another method using window.open, but we need to use
    // the anchor element trick instead to avoid safari detecting this
    // as a popup and blocking it.
    const anchorElement = document.createElement("a")
    anchorElement.href = objectUrl
    anchorElement.target = "_blank"
    anchorElement.click()
}

// matches servers implementation of slugify for outfitter names
export function slugifyOutfitterName(name: string) {
    return slugify(name, {
        replacement: "-",
        remove: /[*+~.()'"!:@]/g,
        locale: "en",
        lower: true,
        strict: true,
    })
}

export function omit<TObject extends object>(
    object: TObject,
    keysToOmit: (keyof TObject)[]
) {
    const objectCopy = { ...object }
    keysToOmit.forEach((key) => delete objectCopy[key])

    return objectCopy
}

// remove any keys that are not in the entity
// also remove any keys that have not changed from the entities values
export function getCleanedUpdateInput<
    TEntity extends Record<string, unknown>,
    TUpdateInput extends Record<string, unknown>,
>({ entity, updateInput }: { entity: TEntity; updateInput: TUpdateInput }) {
    const entityKeys = Object.keys(entity)
    const updateInputKeys = Object.keys(updateInput)

    return updateInputKeys.reduce((cleanedUpdateInput, key) => {
        if (!entityKeys.includes(key)) {
            return cleanedUpdateInput
        }

        const entityValue = entity[key]
        const updateInputValue = updateInput[key]

        if (entityValue !== updateInputValue) {
            return { ...cleanedUpdateInput, [key]: updateInputValue }
        }

        return cleanedUpdateInput
    }, {})
}

export function formatTimeStringFromTime(time: number) {
    const hour = Math.floor(time / 60)
    const minute = time % 60
    const meridiem = hour >= 12 && hour !== 24 ? "PM" : "AM"

    return `${hour % 12 || 12}:${
        minute < 10 ? `0${minute}` : minute
    } ${meridiem}`
}
