import React from "react"
import {
    Box,
    Flex,
    Fade,
    Button,
    Spacer,
    Divider,
    useColorModeValue,
    useBreakpointValue,
    Text,
} from "@chakra-ui/react"
import { BsChevronDoubleLeft } from "react-icons/bs"

import IconLogo from "~images/logos/mb-logo-small.svg"
import TextLogo from "~components/shared/header/logo"
import SidebarContent from "~components/shared/sidebar/sidebar-content"
import PermissionsProvider from "~components/shared//permissions/permissions-provider"
import packageJson from "~package"
import Image from "~components/shared/image"

interface Props {
    readonly isCollapsed: boolean
    readonly setIsCollapsed: (value: boolean) => void
}

export default function Sidebar({ isCollapsed, setIsCollapsed }: Props) {
    const backgroundColor = useColorModeValue("gray.50", "gray.900")
    const width = useBreakpointValue({ base: "250px" })

    return (
        <Flex
            height="100%"
            width={isCollapsed ? "72px" : width}
            direction="column"
            transition="width .3s, background-color .2s, display .3s"
            position="relative"
            bgColor={backgroundColor}
        >
            <Box
                display="flex"
                minH="64px"
                alignItems="center"
                justifyContent="center"
                px={isCollapsed ? 1 : 6}
            >
                {isCollapsed && (
                    <Fade in={isCollapsed} unmountOnExit>
                        <Image w="44px" src={IconLogo} />
                    </Fade>
                )}
                {!isCollapsed && (
                    <Fade in={!isCollapsed} unmountOnExit>
                        <TextLogo />
                    </Fade>
                )}
            </Box>
            <Divider />
            <Box overflow="auto">
                <PermissionsProvider>
                    <SidebarContent isCollapsed={isCollapsed} />
                </PermissionsProvider>
            </Box>
            <Spacer />
            <Box display={["none", "initial"]}>
                <Button
                    width="full"
                    borderRadius={0}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                >
                    <Box
                        transform={isCollapsed ? "rotate(-180deg)" : ""}
                        transition="transform .3s"
                    >
                        <BsChevronDoubleLeft strokeWidth={0.5} />
                    </Box>
                </Button>
                <Divider />

                <Text textAlign="center" color="gray.400" fontSize="xs">
                    v{packageJson.version}
                </Text>
            </Box>
        </Flex>
    )
}
