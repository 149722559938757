import { captureException } from "@sentry/react"
import { add, format, startOfDay, isSameYear } from "date-fns"
import { format as formatWithTz, formatInTimeZone } from "date-fns-tz"
import type { OptionsWithTZ } from "date-fns-tz"

import COPY from "~config/copy-constants"

export const DEFAULT_TIMEZONE = "America/New_York"
export const DEFAULT_LONG_DATE_FORMAT = "MMMM d, yyyy"
export const DEFAULT_SHORT_DATE_FORMAT = "MMM d, yyyy"
const DEFAULT_SHORT_DATE_FORMAT_WITHOUT_YEAR = "MMM d"
export const DEFAULT_TIME_FORMAT = "h:mm a"
const DEFAULT_TIME_FORMAT_WITH_TIMEZONE = "h:mm a (zzz)"
const MONTH_AND_YEAR_FORMAT = "MMMM yyyy"
const DEFAULT_CHECK_IN_DATE_FORMAT = DEFAULT_SHORT_DATE_FORMAT
const DEFAULT_CHECK_IN_TIME_FORMAT = `'at' ${DEFAULT_TIME_FORMAT_WITH_TIMEZONE}`
export const DEFAULT_CHECK_IN_FORMAT = `${DEFAULT_CHECK_IN_DATE_FORMAT} ${DEFAULT_CHECK_IN_TIME_FORMAT}`

function getLocalTimeZone() {
    try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch (error) {
        captureException(error)
        return DEFAULT_TIMEZONE
    }
}

export function getFullFormattedDateTime(date: Date | number | string) {
    return formatInTimeZone(date, getLocalTimeZone(), DEFAULT_CHECK_IN_FORMAT)
}

// Ex. October 1, 2020
function formatDateToLongName(date: Date | number): string {
    return format(date, DEFAULT_LONG_DATE_FORMAT)
}

export function formatDateToMonthAndYear(date: Date | number): string {
    return format(date, MONTH_AND_YEAR_FORMAT)
}

// Ex. Oct 1, 2020
export function formatDateToShortName({
    date,
    shouldIncludeTime = false,
    shouldHideCurrentYear = false,
}: {
    date: Date | number | undefined | null
    shouldIncludeTime?: boolean
    shouldHideCurrentYear?: boolean
}): string {
    if (!date) return ""

    const isCurrentYear = isSameYear(new Date(date), new Date())

    const dateFormat =
        shouldHideCurrentYear && isCurrentYear
            ? DEFAULT_SHORT_DATE_FORMAT_WITHOUT_YEAR
            : DEFAULT_SHORT_DATE_FORMAT

    if (shouldIncludeTime) {
        return format(date, `${dateFormat}, ${DEFAULT_TIME_FORMAT}`)
    }

    return format(date, dateFormat)
}

export function formatDateInTimeZoneToLongName(
    date: Date | number,
    tz: string
) {
    return formatInTimeZone(date, tz, "MMMM do, yyyy")
}

export function formatDatesRange(
    startTimestamp: number,
    endTimestamp: number,
    tzOptions?: OptionsWithTZ
): string {
    const startDate = new Date(startTimestamp)
    const endDate = new Date(endTimestamp)

    // If the dates are in different years, include the year in the output
    if (startDate.getFullYear() !== endDate.getFullYear()) {
        return `${formatWithTz(
            startDate,
            DEFAULT_SHORT_DATE_FORMAT,
            tzOptions
        )} - ${formatWithTz(endDate, DEFAULT_SHORT_DATE_FORMAT, tzOptions)}`
    }

    // If the dates are in different months, include the month and day for both dates
    if (startDate.getMonth() !== endDate.getMonth()) {
        return `${formatWithTz(startDate, "MMM d", tzOptions)} - ${formatWithTz(
            endDate,
            DEFAULT_SHORT_DATE_FORMAT,
            tzOptions
        )}`
    }

    // If the dates are in the same month, but different days, include both days
    if (startDate.getDate() !== endDate.getDate()) {
        return `${formatWithTz(startDate, "MMM d", tzOptions)} - ${formatWithTz(
            endDate,
            "d, yyyy",
            tzOptions
        )}`
    }

    // If the dates are the same day, only include the day once
    return `${formatWithTz(startDate, DEFAULT_SHORT_DATE_FORMAT, tzOptions)}`
}

export function formatStartAndEndDatesSelection(dates: Date[] | number[]) {
    if (dates.length < 2) return COPY.NO_DATES_SELECTED

    const [startDate, endDate] = dates
    const formattedStartDate = formatDateToLongName(startDate)
    const formattedEndDate = formatDateToLongName(endDate)

    return `${formattedStartDate} - ${formattedEndDate}`
}

export const formatTimeStringFromMinutes = (minutes: number) => {
    const startOfToday = startOfDay(new Date())
    const time = add(startOfToday, { minutes })

    return format(time, DEFAULT_TIME_FORMAT)
}
