import type { BoxProps } from "@chakra-ui/react"
import { Box } from "@chakra-ui/react"
import React from "react"

interface ConditionalBoxProps extends BoxProps {
    readonly showIf: boolean | null | undefined
}

export default function ConditionalBox({
    showIf,
    ...props
}: ConditionalBoxProps) {
    return <Box display={showIf ? "block" : "none"} {...props} />
}
