import { useColorModeValue, useTheme } from "@chakra-ui/react"

import type { CustomTheme } from "src/@chakra-ui/gatsby-plugin/theme"

export const useColors = () => {
    const { colors } = useTheme<CustomTheme>()

    const label = useColorModeValue(colors.gray["900"], colors.white)
    const secondaryLabel = useColorModeValue(
        colors.gray["600"],
        colors.gray["400"]
    )
    const bgColor = useColorModeValue(colors.white, colors.gray["900"])
    const bgColorSecondary = useColorModeValue(
        colors.gray["50"],
        colors.gray["800"]
    )
    const bgColorTertiary = useColorModeValue(colors.white, colors.gray["800"])
    const bgColorPrimary = useColorModeValue("brand.50", "brand.800")
    const bgColorHover = useColorModeValue("gray.100", "gray.800")
    const bgColorInfo = useColorModeValue("blue.50", "blue.900")
    const bgColorHighlight = useColorModeValue("brand.50", "brand.800")
    const borderColor = useColorModeValue(
        colors.gray["200"],
        colors.gray["700"]
    )
    const hoverColor = useColorModeValue(colors.gray["200"], colors.gray["800"])
    const titleColor = useColorModeValue(colors.custom.darkPurple, colors.white)
    const primaryButtonColor = "brand.500"
    const selectedTextColor = "brand.500"
    const focusBorderColor = colors.brand["400"]
    const white = colors.white
    const black = colors.black
    const gray = colors.custom.gray
    const successColor = "green.400"
    const warningColor = "yellow.400"
    const errorColor = "red.500"
    const disabledColor = useColorModeValue("gray.400", "gray.600")
    const dangerColor = "red.400"
    const secondaryText = "GrayText"
    const optionText = useColorModeValue("gray.600", "gray.400")

    const successColorScheme = "green"
    const warningColorScheme = "yellow"
    const warningAltColorScheme = "orange"
    const dangerColorScheme = "red"
    const brandColorScheme = "brand"
    const infoColorScheme = "blue"
    const defaultColorScheme = "gray"

    const boxShadowPrimary = useColorModeValue(
        "0px 2px 15px rgba(216, 210, 252, 0.45)",
        "0px 2px 15px rgba(216, 210, 252, 0.15)"
    )

    const borderColorPrimary = useColorModeValue("gray.100", "gray.700")
    const borderColorDark = useColorModeValue("gray.300", "gray.500")
    const primaryColorGradient = "linear(to-r, #F76539, #e2582e)"
    const listingTextColor = useColorModeValue(colors.custom.aqua, colors.white)

    const okStatus = "teal.300"
    const notOkStatus = colors.custom.brightOrange
    const undeterminedStatus = colors.custom.mustard

    const shareBackground = colors.custom.shareBackground
    const shareBackgroundHover = colors.custom.shareBackgroundHover
    const lightGray = colors.custom.lightGray
    const lightGray2 = "gray.400"
    const tooltipCardBackgroundColor = "gray.600"

    return {
        label,
        secondaryLabel,
        bgColor,
        bgColorSecondary,
        bgColorTertiary,
        bgColorPrimary,
        bgColorHover,
        bgColorInfo,
        bgColorHighlight,
        borderColor,
        hoverColor,
        titleColor,
        boxShadowPrimary,
        borderColorPrimary,
        borderColorDark,
        primaryButtonColor,
        successColor,
        warningColor,
        errorColor,
        disabledColor,
        optionText,
        dangerColor,
        secondaryText,
        primaryColorGradient,
        white,
        black,
        gray,
        selectedTextColor,
        successColorScheme,
        warningColorScheme,
        warningAltColorScheme,
        dangerColorScheme,
        brandColorScheme,
        infoColorScheme,
        defaultColorScheme,
        focusBorderColor,
        listingTextColor,
        okStatus,
        notOkStatus,
        undeterminedStatus,
        shareBackground,
        shareBackgroundHover,
        lightGray,
        lightGray2,
        tooltipCardBackgroundColor,
        baseColors: colors,
    }
}
