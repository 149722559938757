import React, { useState } from "react"
import { createPortal } from "react-dom"
import { Box, Icon, IconButton, useTheme } from "@chakra-ui/react"
import { FiMessageSquare } from "react-icons/fi"
import { IoClose } from "react-icons/io5"

import { openChatWidget } from "~utils/helpers/hubspot-helpers"
import {
    HUBSPOT_INLINE_EMBED_ELEMENT_ID,
    PROFILE_MENU_LABELS,
    TEST_IDS,
} from "~config/constants"
import IconMenuItem from "~components/shared/icon-menu-item"
import type { CustomTheme } from "src/@chakra-ui/gatsby-plugin/theme"

export default function HubspotMenuItem() {
    const [isOpen, setIsOpen] = useState(false)
    const { colors } = useTheme<CustomTheme>()

    return (
        <>
            <IconMenuItem
                data-testid={TEST_IDS.HUBSPOT_MENU_ITEM}
                icon={FiMessageSquare}
                label={PROFILE_MENU_LABELS.CHAT_WITH_SUPPORT}
                onClick={() => {
                    setIsOpen(true)
                    openChatWidget()
                }}
            />
            {createPortal(
                <Box
                    position="fixed"
                    width={{ lg: "420px", base: "100%" }}
                    height={{ lg: "674px", base: "100%" }}
                    bottom={{ lg: 50, base: 0 }}
                    right={{ lg: 50, base: 0 }}
                    zIndex={9999}
                    visibility={isOpen ? "visible" : "hidden"}
                    id={HUBSPOT_INLINE_EMBED_ELEMENT_ID}
                >
                    <IconButton
                        aria-label="close chat"
                        icon={<Icon as={IoClose} height="60%" width="60%" />}
                        position="absolute"
                        bottom={{ lg: -5, base: "auto" }}
                        right={{ lg: -5, base: "55px" }}
                        top={{ lg: "auto", base: "11px" }}
                        size="lg"
                        colorScheme="orange"
                        backgroundColor={colors.custom.hubspotOrange}
                        rounded="full"
                        onClick={() => setIsOpen(false)}
                    />
                </Box>,
                document.body
            )}
        </>
    )
}
