import { Tag } from "@chakra-ui/react"
import React from "react"

import { useUser } from "~config/context-providers/user-provider"
import { USER_ROLE_LABELS } from "~config/constants"

const VIEWING_LABEL = "Viewing"

export default function ProfileTag() {
    const { isImpersonating, isAdmin, isOutfitter } = useUser()

    if (isImpersonating) {
        return (
            <Tag size="sm" colorScheme="brand">
                {VIEWING_LABEL}
            </Tag>
        )
    }

    return (
        <Tag size="sm" colorScheme={isAdmin ? "orange" : "gray"}>
            {getRoleLabel({ isAdmin, isOutfitter })}
        </Tag>
    )
}
function getRoleLabel({
    isAdmin,
    isOutfitter,
}: {
    isAdmin?: boolean
    isOutfitter?: boolean
}) {
    if (isAdmin) return USER_ROLE_LABELS.ADMIN

    return isOutfitter ? USER_ROLE_LABELS.OUTFITTER : USER_ROLE_LABELS.USER
}
