import React from "react"
import {
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
    useColorModeValue,
} from "@chakra-ui/react"

import TextLogo from "~components/shared/header/logo"
import SidebarContent from "~components/shared/sidebar/sidebar-content"
import PermissionsProvider from "~components/shared//permissions/permissions-provider"

type Props = {
    readonly isOpen: boolean
    readonly closeSidebar: () => void
}

function SidebarDrawer({ isOpen, closeSidebar }: Props): React.ReactElement {
    const bgColor = useColorModeValue("white", "gray.900")

    return (
        <Drawer isOpen={isOpen} placement="left" onClose={closeSidebar}>
            <DrawerOverlay>
                <DrawerContent
                    mt={4}
                    mb={4}
                    ml={4}
                    maxW="280px"
                    rounded="2xl"
                    bgColor={bgColor}
                >
                    <DrawerCloseButton top={3} right={2} />
                    <DrawerHeader>
                        <TextLogo py={1} width="168px" />
                    </DrawerHeader>
                    <DrawerBody pl={0} pr={0} pt={0}>
                        <PermissionsProvider>
                            <SidebarContent isCollapsed={false} />
                        </PermissionsProvider>
                    </DrawerBody>
                </DrawerContent>
            </DrawerOverlay>
        </Drawer>
    )
}

export default SidebarDrawer
