import React from "react"
import type { SpinnerProps } from "@chakra-ui/react"
import { Box, Spinner as ChakraSpinner } from "@chakra-ui/react"

import { TEST_IDS } from "~config/constants"

interface Props extends SpinnerProps {
    readonly fullHeight?: boolean
}

export default function Spinner({ fullHeight = false, ...props }: Props) {
    return (
        <Box
            h={fullHeight ? "80vh" : "auto"}
            display="flex"
            alignItems="center"
            justifyContent="center"
            overflow="hidden"
            data-testid={TEST_IDS.SPINNER}
        >
            <ChakraSpinner
                size="lg"
                thickness="4px"
                label="Loading..."
                {...props}
            />
        </Box>
    )
}
